exports.columns = [
  { data: 'id' }
  { data: 'customer_id' }
  { data: 'reference' }
  { data: 'reference_pol' }
  { data: 'subtotal' }
  { data: 'iva' }
  { data: 'total' }
  { data: 'total_epayco' }
  { data: 'email_buyer' }
  { data: 'phone_buyer' }
  { data: 'status', sortable: false, searchable: false }
  { data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'  }
]

exports.config = {}
