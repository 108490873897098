exports.columns = [
  {data: 'id'},
  {data: 'title'},
  {data: 'open'},
  {data: 'live'},
  {data: 'opening_date'},
  {data: 'finished'},
  {data: 'enabled'},
  {data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}
]
exports.config = {}
