import jsZip from 'jszip'

$.fn.dataTable = require 'datatables.net-dt'
require 'datatables.net-buttons-dt'
require 'datatables.net-buttons/js/buttons.html5.js'
require 'datatables.net-buttons/js/buttons.print.js'
require 'datatables.net-buttons/js/buttons.colVis.js'
require 'datatables.net-colreorder-dt'
import datatableConfig from './config'

window.JSZip = jsZip;
$.extend $.fn.dataTable.defaults, datatableConfig

export default class DataTable
  constructor: ({model, uid, columns, custom_url='', options, lang = 'es', order}) ->
    @model = model
    @uid = uid
    @columns = columns
    @custom_url = custom_url
    @lang = lang
    @options = options
    @order = order
  init: ()->
    {uid, model, columns, custom_url, options, order} = @

    unless $.fn.dataTable.isDataTable('#the_datatable')
      csrf = document.querySelector('[name="csrf-token"]').content
      url = if custom_url then custom_url else "/admin/#{ uid }/#{ model }/datatable.json"
      dt_initialization = Object.assign(options, {
        ajax:
          url: url, method: 'post'
          headers:
            'X-CSRF-Token': csrf
        columns
        order: [order]
      })
      $('#the_datatable').dataTable(dt_initialization)

  @turbolinks_fix: () ->
    if $.fn.dataTable.isDataTable('#the_datatable')
      the_instance = $('#the_datatable').dataTable()
      the_instance.fnClearTable()
      the_instance.fnDestroy()
