exports.columns = [
  { data: 'id' }
  { data: 'name' }
  { data: 'last_name' }
  { data: 'document' }
  { data: 'country' }
  { data: 'phone' }
  { data: 'mobile' }
  { data: 'email' }
  { data: 'verified' }
  { data: 'enabled' }
  { data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap' }
]

exports.config = {
  lengthMenu: [
    10, 20, 50, 100, 300, 500
  ]
}
