exports.columns = [
  {data: 'id'},
  {data: 'title'},
  {data: 'auction'},
  {data: 'category'},
  {data: 'subcategory'},
  {data: 'subcategory_type'},
  {data: 'initial_bid'},
  {data: 'enabled'},
  {data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}
]
exports.config = {
  lengthMenu: [
    10, 20, 50, 100, 300, 500
  ]
}
