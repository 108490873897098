exports.columns = [
  {data: 'id'},
  {data: 'auction'},
  {data: 'lot'},
  {data: 'p_system'},
  {data: 'p_customer'},
  {data: 'p_winner_bid'},
  {data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}
]
exports.config = {
  lengthMenu: [
    10, 20, 50, 100, 300, 500
  ]
}
