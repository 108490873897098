exports.columns = [
  {data: 'id'},
  {data: 'type'},
  {data: 'free_shipping'},
  {data: 'local'},
  {data: 'nacional'},
  {data: 'international'},
  {data: 'created_at'},
  {data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}
]
exports.config = {}
