exports.columns = [
  {data: 'id'},
  {data: 'customer'},
  {data: 'bid_sistem'},
  {data: 'bid_customer'},
  {data: 'created_at'},
  {data: 'automatic'},
  {data: 'winner_bid'},
  {data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}
]
exports.config = {}
