exports.columns = [
  { data: 'id' }
  { data: 'code' }
  { data: 'name' }
  { data: 'category' }
  { data: 'subcategory' }
  { data: 'subcategory_type' }
  { data: 'unit' }
  { data: 'price' }
  { data: 'discount' }
  { data: 'price_discount' }
  { data: 'status' }
  { data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap' }
]

exports.config = {
  lengthMenu: [
    10, 20, 50, 100, 300, 500
  ]
}
