exports.columns = [
  {data: 'id'},
  {data: 'code'},
  {data: 'total_units'},
  {data: 'units_left'},
  {data: 'discount'},
  {data: 'condition'},
  {data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}
]
exports.config = {}
