import DataTable from '../datatables';

document.addEventListener 'turbolinks:load', () ->
  element = document.getElementById('datatable')
  return false if(!element)

  { model, uid, submodule, customUrl, order } = element.dataset
  df_file = require "../datatables/models/#{ model }"
  finalOrder = []
  if order
    finalOrder = order.split(',')
    finalOrder[0] = parseInt(finalOrder[0])

  new DataTable(
    {
      model,
      uid,
      columns: df_file.columns,
      submodule,
      custom_url: customUrl,
      options: df_file.config,
      order: finalOrder
    }
  ).init()

document.addEventListener 'turbolinks:before-cache', () -> DataTable.turbolinks_fix()
